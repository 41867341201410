<template>
  <div>
    <b-card-actions
      title="Filters"
      action-collapse
      collapsed
    >
      <b-row>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Delivered Date"
            label-for="delivered_date"
          >
            <div class="position-relative">
              <flat-pickr
                v-model="filters['status_date']"
                class="form-control"
                :config="{ mode: 'range',enableTime: true, dateFormat: 'Z', altInput: true, altFormat: 'Y-m-d H:i:s'}"
                placeholder="Select Date"
                :disabled="disabled"
              />
              <div
                v-if="filters['status_date']"
                class="flatpickr-cancel"
                @click="clearDate('status_date')"
              >
                <feather-icon icon="XIcon" />
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <b-form-group
            label="Rider Deposit Status"
            label-for="deposit_status"
          >
            <v-select
              v-model="filters['staff_deposit_status']"
              :options="financeStatusOptions"
              :reduce="option => option.key"
              label="name"
              placeholder="Select Status"
            >
              <template v-slot:option="option">
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <filter-buttons
            :filter-data="filters"
            :apply-filters="applyFilters"
            :clear-filters="clearFilters"
          />
        </b-col>
      </b-row>
    </b-card-actions>
    <b-card
      no-body
    >
      <b-overlay
        :show="submitLoading"
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <div
          class="m-2"
        >
          <b-row
            cols="12"
            class="pt-1 pb-1"
          >
            <b-col
              class="justify-content-end"
            >
              <b-row
                class="d-flex align-items-center justify-content-end mr-1"
              >
                <b-col class="d-flex justify-content-start">
                  <div
                    class="font-weight-bolder text-muted mobile-margin"
                  >
                    {{ `${waybillSelected.length} record${waybillSelected.length === 1 ? '':'s'}` }} selected
                  </div>
                </b-col>
                <b-col md="3">
                  <v-select
                    v-model="selectedBranch"
                    :options="Branch"
                    :reduce="option => option.id"
                    label="name"
                    placeholder="Select Branch"
                    class="ml-1 mobile-margin"
                    @input="deselectAll()"
                  >
                    <template v-slot:option="option">
                      {{ option.name }}
                    </template>
                  </v-select>
                </b-col>
                <div>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mobile-margin custom-button-color-blur  mr-2"
                    @click="loadBills"
                  >
                    Get Waybills
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mobile-margin custom-button-color"
                    :disabled="waybillSelected.length > 0? false : true"
                    @click="SubmitDeposit"
                  >
                    Submit
                  </b-button>
                </div>
                <div class="ml-1">
                  <ReportTableButtons
                    :items="items"
                    :json_fieldz="json_fields"
                    :name="`${downloadReportName} - Page ${page}.xls`"
                    :bulkname="`${downloadReportName}.xls`"
                    :fetch="fetchWaybillForBranchNoPagination"
                    @refresh="fetchWaybillForBranch"
                  />
                </div>
              </b-row>
            </b-col>
          </b-row>
        </div>
        <div>
          <b-row>
            <b-col md="12">
              <b-overlay
                id="overlay-background"
                blur="10px"
                opacity="0.30"
                rounded="sm"
                variant="light"
              >
                <b-table
                  :per-page="perPage"
                  :items="items"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  hover
                  responsive
                  striped
                  show-empty
                  class="table-responsive"
                >
                  <template #empty>
                    <TableDataFetching
                      :rows="items"
                      :data-loading="dataLoading"
                    />
                  </template>
                  <template #head(id)>
                    <div class="d-flex align-items-start">
                      <div>
                        <b-form-checkbox
                          id="selectAllCheckbox"
                          v-model="selectAllCheckbox"
                          name="selectAllCheckbox"
                          @change="onClickSelectAllCheckbox()"
                        />
                      </div>
                    </div>
                  </template>
                  <template #cell(id)="data">
                    <div class="d-flex align-items-start">
                      <div>
                        <div class="font-weight-bolder">
                          <b-form-checkbox
                            v-model="waybillSelected"
                            :value="data.item.waybill_number"
                          />
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #cell(status.key)="data">
                    <order-status-badge :status-key="data.value" />
                  </template>
                  <template #cell(logo_url)="data">
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="font-weight-bolder">
                          {{ data.item.waybill_number }}
                        </div>
                        <div class="font-small-2">
                          {{ data.item.order_no }}
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #cell(customer_name)="data">
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="font-weight-bolder">
                          {{ data.value }}
                        </div>
                        <div class="font-small-2">
                          {{ data.item.customer_email }}
                        </div>
                        <div class="font-small-2">
                          <div><b>Primary number: </b>{{ data.item.customer_phone }}</div>
                        </div>
                        <div
                          v-if="data.item.customer_secondary_phone !== null"
                          class="font-small-2"
                        >
                          <div><b>Second number: </b>{{ data.item.customer_secondary_phone }}</div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #cell(cod)="data">
                    <span>{{ __numberWithCommas(data.value, true) }}</span>
                  </template>
                  <template #cell(collected_cod)="data">
                    <span>{{ __numberWithCommas(data.value, true) }}</span>
                  </template>
                  <template #cell(rider_commission)="data">
                    <span>{{ __numberWithCommas(data.value, true) }}</span>
                  </template>
                  <template #cell(rider_deposit)="data">
                    <div
                      class="d-flex justify-content-center font-weight-bolder"
                      style="margin-bottom:2px;"
                    >
                      {{ data.value.ref_no || '---' }}
                    </div>
                    <div class="d-flex justify-content-center">
                      <finance-status-badge
                        :status-key="data.value.statuses? data.value.statuses.name : ''"
                        :finance-status="financeStatusOptions"
                      />
                    </div>
                  </template>
                </b-table>
              </b-overlay>
              <div class="mx-2 mb-2">
                <b-row
                  cols="12"
                >
                  <b-col
                    class="d-flex align-items-center justify-content-sm-start"
                    md="3"
                  >
                    <label class="width-75">Per page</label>
                    <v-select
                      v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="pageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block width-100"
                    />
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    class="d-flex align-items-center justify-content-sm-end"
                    md="9"
                  >
                    <span class="text-muted pagination-text  mt-1 mt-md-0 mr-1">Showing {{ meta.from }} to {{ meta.to }} of {{ meta.total }} entries</span>
                    <b-pagination
                      v-model="page"
                      :total-rows="(meta.total)? meta.total : 0"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>

                  </b-col>

                </b-row>
              </div>
            </b-col>
          </b-row>
        </div>
        <!-- <div>
          <b-overlay
            id="overlay-background"
            :show="Expenceloading"
            blur="10px"
            opacity="0.30"
            rounded="sm"
            variant="light"
          >
            <div>
              <b-form-group
                label="Expenses"
                class="ml-2"
              >
                <b-row
                  v-for="(expense, expense_index) in expenses_section"
                  :key="expense_index"
                >
                  <b-col md="1">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      class="mt-1 mt-md-2 mb-1 mb-md-0 btn-icon"
                      @click="addSectionExpences"
                    >
                      <feather-icon
                        icon="PlusIcon"
                      />
                    </b-button>
                  </b-col>
                  <b-col
                    cols="7"
                    sm="6"
                    md="4"
                  >
                    <b-form-group
                      label="Type"
                      label-for="v-ret-ao"
                    >
                      <v-select
                        v-model="expense.type"
                        :options="ExpenceOptions"
                        :reduce="option => option.type"
                        label="name"
                        placeholder="Select Expenses"
                        class="w-250"
                      >
                        <template v-slot:option="option">
                          {{ option.name }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="7"
                    sm="4"
                    md="2"
                  >
                    <b-form-group
                      label="Amount"
                      label-for="v-ret-ao"
                    >
                      <b-form-input
                        id="v-ret-ao"
                        v-model="expense.amount"
                        size="md"
                        type="number"
                        autocomplete="nope"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="1">
                    <div v-if="expense_index !== 0">
                      <b-button
                        v-ripple.400="'rgba(238, 114, 115)'"
                        variant="danger"
                        class="mt-2 btn-icon"
                        @click="removeSectionExpences"
                      >
                        <feather-icon
                          icon="Trash2Icon"
                        />
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="3">
                    <span
                      style="visibility: hidden"
                    >Spacing for 1 row</span>
                  </b-col>
                  <b-col md="3" />
                </b-row>
                <b-row>
                  <b-col md="3">
                    <span
                      style="visibility: hidden"
                    >Spacing for 1 row</span>
                  </b-col>
                  <b-col md="3" />
                </b-row>
                <b-row>
                  <b-col cols="1" />
                </b-row>
              </b-form-group>
            </div>
          </b-overlay>
        </div> -->
        <!-- <div>
          <div>
            <b-form-group>
              <b-row>
                <b-col md="10" />
                <b-col
                  md="2"
                  class="d-flex align-items-center justify-content-end"
                >
                  submit button was here
                </b-col>
                <b-row>
                  <b-col cols="1" />
                </b-row>
              </b-row>
            </b-form-group>
          </div>
        </div> -->
      </b-overlay>
    </b-card>
    <deposit-detail-modal ref="deposit_detail_modal" />
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BFormGroup,
  // BFormInput,
  BRow,
  VBModal,
  BButton,
  VBTooltip,
  BPagination,
  BFormCheckbox,
  BTable,
  BOverlay,
} from 'bootstrap-vue'
import FinanceStatusBadge from '@core/components/status/FinanceStatusBadge.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import DepositDetailModal from '@/components/FinanceModuleForms/InfoModals/DepositDetailModal.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import OrderStatusBadge from '@core/components/status/OrderStatusBadge.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Filter from '@core/mixins/filter'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import FilterButtons from '@/components/Filter/FilterButtons.vue'

const ResourceRepository = RepositoryFactory.get('resource')
const FinanceRepository = RepositoryFactory.get('finance')

export default {
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BOverlay,
    BCol,
    BFormGroup,
    // BFormInput,
    BFormCheckbox,
    BRow,
    vSelect,
    BCard,
    BButton,
    BPagination,
    BTable,
    BCardActions,
    OrderStatusBadge,
    FinanceStatusBadge,
    DepositDetailModal,
    flatPickr,
    FilterButtons,
  },
  mixins: [Filter, SuccessMessage, ErrorMessage, heightTransition],
  data() {
    return {
      filters: {},
      financeStatusOptions: [],
      ExpenceOptions: [],
      Expenceloading: false,
      dataLoading: false,
      selectedBranch: '',
      Branch: [],
      waybillSelected: [],
      options: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
      },
      submitLoading: false,
      sort: '',
      perPage: 10,
      pageOptions: [10, 20, 50],
      expenseType: [
        { name: 'Fuel', value: 'fuel' },
        { name: 'Food', value: 'food' },
      ],
      totalRows: 1,
      page: 1,
      meta: {},
      dir: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      disabled: true,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id',
          label: '#',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'logo_url',
          label: 'WayBill',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'customer_name',
          label: 'Customer Contact',
          sortable: true,
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'cod',
          label: 'COD',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'collected_cod',
          label: 'Collected COD',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'rider_commission',
          label: 'Rider Commission',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'rider_deposit',
          label: 'Rider Deposit',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'status.key',
          label: 'Status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      items: [],
      expenses_section: [
        {
          id: 1,
          type: '',
          amount: '',
        },
      ],
      json_fields: {
        'Waybill Number': 'waybill_number',
        'Order ID': 'order_no',
        'Customer Name': {
          field: 'customer_name',
          callback: value => `"${value}"`,
        },
        'Customer Email': {
          field: 'customer_email',
          callback: value => `"${value}"`,
        },
        'Customer Phone': 'customer_phone',
        'Second Phone Number': 'customer_secondary_phone',
        COD: 'cod',
        'Collected Cod': 'collected_cod',
        'Rider Commission': 'rider_commission',
        'Rider Deposit Reference No': {
          field: 'rider_deposit',
          callback: value => (value.ref_no || 'N/A'),
        },
        Status: {
          field: 'status.name',
          callback: value => `"${value}"`,
        },
      },
      errorCount: 0,
      selectAllCheckbox: false,
      downloadReportName: 'Branch-New-Deposits',
      pageWithSelectAll: [],
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchWaybillForBranch()
      },
    },
    deep: true,
    page() {
      this.fetchWaybillForBranch()
    },
    perPage() {
      this.fetchWaybillForBranch()
    },
    selectedBranch: {
      async handler(val) {
        if (val === null) {
          this.clearDate('status_date')
          this.disabled = true
        }
      },
    },
  },
  mounted() {
    this.financeStatusOptions = JSON.parse(localStorage.getItem('finance_statuses'))
    this.readDataforAgency()
    // this.readDataforExpencesMethod()
  },
  methods: {
    async readDataforExpencesMethod() {
      try {
        this.Expenceloading = true
        const { data } = (await FinanceRepository.getExpencesMethod()).data
        this.ExpenceOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.Expenceloading = false
    },
    async fetchWaybillForBranch() {
      this.dataLoading = true
      this.items = []
      const isChecked = this.pageWithSelectAll.includes(this.page)
      if (!isChecked) {
        this.selectAllCheckbox = false
      } else {
        this.selectAllCheckbox = true
      }
      if (this.selectedBranch !== '') {
        try {
          // eslint-disable-next-line no-underscore-dangle
          this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
          const { data } = (await FinanceRepository.getAllBranchWaybillList(this.page, this.perPage, this.selectedBranch, this.filterQuery))
          this.items = data.data
          this.meta = data.meta
          this.disabled = false
        } catch (e) {
          this.showErrorMessage('Sorry for the inconvenience! Please Try Again!!')
        }
      }
      this.dataLoading = false
    },
    async fetchWaybillForBranchNoPagination() {
      try {
        // eslint-disable-next-line no-underscore-dangle
        this.filterQuery = this.__refineDateQuery(this.filterQuery, [this.filters])
        const { data } = (await FinanceRepository.getAllBranchWaybillListNoPagination(this.selectedBranch, this.filterQuery))
        return data.data
      } catch (e) {
        this.convertAndNotifyError(e)
        return []
      }
    },
    async readDataforAgency() {
      try {
        const { data } = (await ResourceRepository.getBranchListDropdown()).data
        this.Branch = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    handleChangePage(page) {
      this.page = page
      this.fetchWaybillForBranch()
    },
    applyFilters() {
      this.fetchWaybillForBranch()
    },
    clearFilters() {
      this.filters = {}
      this.$nextTick(() => {
        this.fetchWaybillForBranch()
      })
    },
    selectAll() {
      this.waybillSelected = this.items.map(n => n.waybill_number)
    },
    deselectAll() {
      this.waybillSelected = []
      this.selectAllCheckbox = false
      this.items = []
      this.meta = {}
    },
    onClickSelectAllCheckbox() {
      if (this.selectAllCheckbox) {
        // If the "Select All" checkbox is checked, select all waybill numbers.
        this.waybillSelected = [...this.waybillSelected, ...this.items.map(n => n.waybill_number)]
        this.pageWithSelectAll = [...this.pageWithSelectAll, this.page]
      } else {
        this.waybillSelected = []
        this.pageWithSelectAll = []
      }
    },
    addSectionExpences() {
      const object = {
        id: ((this.expenses_section.length) + 1),
        type: '',
        amount: '',
      }
      this.expenses_section.push(object)
    },
    removeSectionExpences() {
      this.expenses_section.pop()
    },
    SubmitDeposit() {
      const depositDate = new Date().toISOString().slice(0, 10)
      const deposit = {
        warehouse_id: this.selectedBranch,
        waybill_numbers: this.waybillSelected,
        deposit_date: depositDate,
      }
      if (this.waybillSelected.length === 0) {
        this.showErrorMessage('Please Select Waybills First')
      } else if (this.selectedBranch !== this.items[0].warehouse_id) {
        this.showErrorMessage('Selected orders do not match the selected branch')
      } else if (this.expenses_section.length > 1) {
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= this.expenses_section.length; i++) {
          if (this.expenses_section[i].type === '' || this.expenses_section[i].amount === '') {
            // eslint-disable-next-line no-plusplus
            this.errorCount++
          }
          break
        }
        if (this.errorCount > 0) {
          this.showErrorMessage('Please Check if data is filled')
        } else {
          deposit.expenses = this.expenses_section
          this.handleSubmit(deposit)
        }
      } else if (this.expenses_section.length === 1) {
        // eslint-disable-next-line no-plusplus
        if (this.expenses_section[0].type === '' || this.expenses_section[0].amount === '') {
          // eslint-disable-next-line no-plusplus
          this.errorCount++
        }
        if (this.errorCount > 0) {
          deposit.expenses = []
          this.handleSubmit(deposit)
        } else {
          deposit.expenses = this.expenses_section
          this.handleSubmit(deposit)
        }
      } else {
        deposit.expenses = this.expenses_section
        this.handleSubmit(deposit)
      }
    },
    async handleSubmit(deposit) {
      this.$refs.deposit_detail_modal.openModal(this.waybillSelected, this.createDeposit, deposit, 'branch')
    },
    async createDeposit(deposit) {
      const res = await FinanceRepository.createBranchFinanceDeposit(deposit)
      if (res.status === 201) {
        this.showSuccessMessage('Data Added Successfully')
        await this.$router.push('/operations/branch-finance')
      } else {
        this.showErrorMessage('Cannot Add Deposit')
        await this.$router.push('/operations/branch-finance')
      }
    },
    async loadBills() {
      if (!this.selectedBranch) {
        this.showErrorMessage('Please Select an Branch')
      } else {
        this.deselectAll()
        await this.fetchWaybillForBranch()
      }
    },
    clearDate(filterKey) {
      this.filters[filterKey] = ''
    },
  },
}
</script>
<style scoped lang="scss">
@import '../../@core/scss/vue/libs/vue-select.scss';
@import '../../@core/scss/vue/libs/vue-flatpicker.scss';
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
.custom-button-color-blur {
  background-color: #6da5ff !important;
  border-color: #6da5ff !important;
}
@media (max-width: 767px) {
  .table-responsive {
    position: sticky;
    top: 0;
  }
  .mobile-margin {
    margin-bottom: 20px;
    width: 100%;
  }
  .pagination-text {
    font-size: 0.75rem;
  }
}
</style>
